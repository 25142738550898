import * as zod from 'zod';

export const DBBackupStatus = zod.enum([
  'creating',
  'restoring',
  'failed',
  'success',
]);

export type DBBackupStatus = zod.infer<typeof DBBackupStatus>;

export const DBBackupData = zod.object({
  key: zod.string(),
  path: zod.string(),
  robotID: zod.string(),
  backupQuota: zod.number().optional(),
  date: zod.date(),
  status: zod.optional(DBBackupStatus),
  error: zod.optional(zod.string()),
  params: zod.record(zod.any()).optional(),
});

export type DBBackupData = zod.infer<typeof DBBackupData>;
